<template>
    <div id="dc-woocommerce">
        <div id="dc-woocommerce-header">
            <h3>Online Orders</h3>
            <b-button class="dc-button-primary" @click="refreshAccessToken">Refresh Token</b-button>
        </div>

        <hr />

        <!-- Loading Spinner -->
        <div v-if="ordersCompleted.length == 0" class="dc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <!-- Tabs -->
        <b-tabs card v-else>
            <b-tab title="On-Hold" active>
                <!-- On-Hold -->
                <v-client-table :data="ordersOnHold" :columns="columns" :options="options">
                    <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                        <b-icon-person-circle class="dc-icon-customer" @click="onClickFindCustomer(row)"></b-icon-person-circle>
                        <b-icon-arrow-right-circle-fill class="dc-icon-view" v-b-toggle.app-sidebar-right @click="onClickViewSidebar(row)"></b-icon-arrow-right-circle-fill>
                    </div>
                    <div class="dc-date-picker" slot="deliveryDate" slot-scope="{ row }">
                        <datepicker
                            :value="row.deliveryDate"
                            @selected="onDeliveryDateChange($event, row)"
                            :format="customFormatter"
                            :disabled-dates="{
                                to: moment()
                                    .subtract(1, 'days')
                                    .toDate(),
                            }"
                        ></datepicker>
                    </div>
                </v-client-table>
            </b-tab>
            <b-tab title="Completed">
                <!-- Completed -->
                <v-client-table :data="ordersCompleted" :columns="columns" :options="options">
                    <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                        <span>{{ row.quickbooksId }}</span>
                        <b-icon-arrow-right-circle-fill class="dc-icon-view" v-b-toggle.app-sidebar-right @click="onClickViewSidebar(row)"></b-icon-arrow-right-circle-fill>
                    </div>
                    <div class="dc-date-picker" slot="deliveryDate" slot-scope="{ row }">
                        {{ row.deliveryDate }}
                    </div>
                </v-client-table>
            </b-tab>
        </b-tabs>

        <!-- Modal - Create Invoice -->
        <b-modal id="dc-modal-create-invoice" ref="dc-modal-create-invoice" size="xl" title="Create Invoice" centered hide-footer no-close-on-backdrop>
            <div id="dc-create-invoice-container">
                <div v-if="createInvoiceFindCustomer" id="dc-find-customer">
                    Finding Customer...
                </div>
                <div v-else id="dc-customer-list">
                    <div v-if="createInvoiceCustomerList.length > 0">
                        <h4>Customer Found</h4>
                        <div v-for="customer in createInvoiceCustomerList" :key="customer.Id">
                            <div class="dc-customer-row">
                                <div class="dc-customer-id">{{ customer.Id }}</div>
                                <div class="dc-customer-name">{{ customer.DisplayName }}</div>
                                <div class="dc-customer-address">{{ customer.BillAddr.Line1 }} {{ customer.BillAddr.City }}</div>
                                <div class="dc-customer-phone">
                                    <span v-if="customer.PrimaryPhone">{{ customer.PrimaryPhone.FreeFormNumber }}</span>
                                </div>
                                <div class="dc-customer-email">
                                    <span v-if="customer.PrimaryEmailAddr">{{ customer.PrimaryEmailAddr.Address }}</span>
                                </div>
                                <div class="dc-customer-radio">
                                    <b-form-radio name="some-radios" @change="onChangeRadioCustomer(customer)"></b-form-radio>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <div id="dc-create-invoice-button">
                            <b-button class="dc-button-primary" @click="onClickCreateInvoice">Create Invoice</b-button>
                            <b-button class="dc-button-primary" @click="onClickCreateCustomer">Create Customer</b-button>
                        </div>
                    </div>
                    <div v-else id="dc-customer-not-found">
                        <h4>No customer found</h4>
                        <b-button class="dc-button-primary" @click="onClickCreateCustomer">Create Customer</b-button>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Full Screen Spinner -->
        <div v-if="fullScreenSpinner">
            <vue-element-loading
                :active="fullScreenSpinner"
                :text="fullScreenSpinnerText"
                background-color="rgba(255, 255, 255, .8)"
                spinner="spinner"
                color="#9eab05"
                :is-full-screen="true"
                size="60"
                :text-style="datePickerTextStyle"
            />
        </div>

        <!-- Sidebar -->
        <b-sidebar id="app-sidebar-right" title="Order Info" right shadow backdrop>
            <Sidebar :orderSidebar="orderSidebar"></Sidebar>
        </b-sidebar>
    </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import APICalls from "./APICalls";
import CustomerAPICalls from "./../customer/APICalls";
import QuickbooksAPICalls from "./../quickbooks/APICalls";
import Datepicker from "vuejs-datepicker";
import * as Endpoints from "./../../constant/api-endpoints";
import Sidebar from "./sidebar/Sidebar";

export default {
    name: "WooCommerce",
    components: {
        Datepicker,
        Sidebar,
    },
    data() {
        return {
            columns: [
                "id",
                "billing.phone",
                "customerName",
                "billingAddress",
                "billing.postcode",
                "linteItemsQuantity",
                "linteItemsName",
                "payment_method",
                "total",
                "dateCreated",
                "requestedDeliveryDate",
                "deliveryDate",
                "deliveryType",
                "actions",
            ],
            ordersOnHold: [],
            ordersCompleted: [],
            createInvoiceFindCustomer: false,
            createInvoiceCustomerList: [],
            createInvoiceOrder: {},
            createInvoiceCustomer: {},
            fullScreenSpinner: false,
            fullScreenSpinnerText: "",
            datePickerTextStyle: { "font-size": "24px", "margin-top": "40px" },
            options: {
                perPage: 50,
                columnsDropdown: true,
                // visibleColumns: ["id", "billing.phone", "customerName", "billingAddress", "billing.postcode", "linteItemsQuantity", "linteItemsName", "total", "requestedDeliveryDate", "deliveryDate", "deliveryType", "actions"],
                orderBy: {
                    column: "id",
                    ascending: true,
                },
                headings: {
                    id: "#",
                    customerName: "Customer",
                    linteItemsName: "Product",
                    linteItemsQuantity: "QTY",
                    dateTime: "Date",
                    quickbooksInvoice: "QB #",
                    "billing.postcode": "Postal",
                    payment_method: "Payment",
                    billingAddress: "Billing",
                    deliveryType: "Delivery Type",
                    dateCreated: "Date Created",
                    requestedDeliveryDate: "Requested Delivery",
                    deliveryDate: "Delivery Date",
                    "billing.phone": "Phone",
                },
                columnsClasses: {
                    id: "table-column-id",
                    customerName: "table-column-customer",
                    linteItemsName: "table-column-product",
                    linteItemsQuantity: "table-column-qty",
                    deliveryDate: "table-column-delivery-date",
                    payment_method: "table-column-payment",
                    billingAddress: "table-column-billing",
                    deliveryType: "table-column-delivery-type",
                    dateCreated: "table-column-date-created",
                    requestedDeliveryDate: "table-column-requested-delivery",
                    "billing.postcode": "table-column-postcode",
                    "billing.phone": "table-column-phone",
                },
                templates: {
                    linteItemsName(h, order) {
                        let res = "";
                        let line_items = order.line_items;

                        for (let index in line_items) {
                            let name = line_items[index].name;
                            if (index != line_items.length - 1) {
                                res += name + "\n\n";
                            } else {
                                res += name;
                            }
                        }
                        return res;
                    },
                    linteItemsQuantity(h, order) {
                        let res = "";
                        let lineItems = order.line_items;

                        for (let index in lineItems) {
                            let qty = lineItems[index].quantity;
                            if (index != lineItems.length - 1) {
                                res += qty + "\n\n";
                            } else {
                                res += qty;
                            }
                        }
                        return res;
                    },
                    billingAddress(h, order) {
                        return `${order.billing.address_1} ${order.billing.city}, ${order.billing.state} ${order.billing.postcode}`;
                    },
                    orderStatus(h, order) {
                        let status = order.status;
                        return status.charAt(0).toUpperCase() + status.slice(1);
                    },
                    payment_method(h, order) {
                        let payment = order.payment_method;
                        return payment.charAt(0).toUpperCase() + payment.slice(1);
                    },
                },
            },
            orderSidebar: {},
        };
    },
    methods: {
        moment: function() {
            return moment();
        },
        customFormatter(date) {
            return moment(date).format("ddd MMM D, YYYY");
        },
        onClickFindCustomer(row) {
            this.createInvoiceFindCustomer = true;
            this.createInvoiceOrder = row;
            this.$refs["dc-modal-create-invoice"].show();
            this.checkCustomerExist(row);
        },
        onClickViewSidebar(row) {
            this.orderSidebar = row;
        },
        onClickCreateInvoice() {
            this.createInvoice();
        },
        onClickCreateCustomer() {
            this.createCustomer();
        },
        onChangeRadioCustomer(customer) {
            this.createInvoiceCustomer = customer;
        },
        onDeliveryDateChange(e, row) {
            row.deliveryDate = new Date(e);
            this.changeDeliveryDate(row.id, moment(row.deliveryDate).format("dddd, MMM D, YYYY"));
        },
        getRequestedDeliveryDate(metadata) {
            let requestedDeliveryDate = _.find(metadata, metadata => {
                if (metadata.key === "Requested Delivery Date" || metadata.key === "<b>Requested</b> Delivery Date") return metadata;
            });
            return requestedDeliveryDate && requestedDeliveryDate.value ? requestedDeliveryDate.value : "";
        },
        getDeliveryDate(metadata) {
            let deliveryDate = _.find(metadata, metadata => {
                if (metadata.key === "deliveryDate") return metadata;
            });
            return deliveryDate && deliveryDate.value ? deliveryDate.value : "";
        },
        getDeliverySpot(metadata) {
            let deliverySpot = _.find(metadata, metadata => {
                if (metadata.key === "_billing_drop_spot") return metadata;
            });
            return deliverySpot && deliverySpot.value ? deliverySpot.value : "";
        },
        getDeliveryInstruction(metadata) {
            let deliveryInstruction = _.find(metadata, metadata => {
                if (metadata.key === "_billing_delivery_instructions") return metadata;
            });
            return deliveryInstruction && deliveryInstruction.value ? deliveryInstruction.value : "";
        },
        getQuickbooksId(metadata) {
            let quickbooksId = _.find(metadata, metadata => {
                if (metadata.key === "quickbooksId") return metadata;
            });
            return quickbooksId && quickbooksId.value ? quickbooksId.value : "";
        },
        // Refresh Token
        async refreshAccessToken() {
            axios
                .get(Endpoints.quickbooks_refresh_token_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then(res => {
                    this.$toasted.show("Refresh Token Success !", { position: "bottom-center", className: "dc-toast-success", duration: 5000 });
                    console.log("Refresh Access Token", res.data);
                })
                .catch(err => {
                    console.log("Refresh Access Token", err);
                });
        },
        async createInvoice() {
            try {
                this.fullScreenSpinnerText = "Creating Invoice...";
                this.fullScreenSpinner = true;
                let customerQBId = _.get(this.createInvoiceCustomer, "Id", "");
                let customerEmail = _.get(this.createInvoiceCustomer, "PrimaryEmailAddr.Address", "");
                let invoiceDate = moment(_.get(this.createInvoiceOrder, "date_created", "")).format("yyyy/MM/DD");

                let Line = [];

                let valid = true;

                // Each item in single ticket
                _.each(this.createInvoiceOrder.line_items, eachItem => {
                    let productQBSku = eachItem.sku;
                    let productQBQty = eachItem.quantity;

                    // Customer error
                    if (!customerQBId || customerQBId.trim().length === 0) {
                        this.$toasted.show("Customer ID not found", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
                        valid = false;
                        return false;
                    }

                    // Product error
                    if (!productQBSku || productQBSku.trim().length === 0) {
                        this.$toasted.show("Product SKU not found", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
                        valid = false;
                        return false;
                    }

                    // Price
                    let quantity = eachItem.quantity;
                    let pricePerUnit = parseFloat(eachItem.price);
                    let amount = pricePerUnit * quantity;

                    // Success - Push
                    Line.push({
                        DetailType: "SalesItemLineDetail",
                        SalesItemLineDetail: {
                            ServiceDate: this.createInvoiceOrder.deliveryDate,
                            ItemRef: {
                                value: "",
                            },
                            Qty: productQBQty,
                            UnitPrice: pricePerUnit,
                            TaxCodeRef: {
                                value: "39", // HST ON
                            },
                            SKU: productQBSku,
                        },
                        Amount: amount,
                    });
                });

                if (!valid) {
                    this.fullScreenSpinner = false;
                    return;
                }

                // Delivery Fee
                let shippingTotal = _.get(this.createInvoiceOrder, "shipping_total", "");
                if (shippingTotal && shippingTotal.length != 0 && shippingTotal != "0.00") {
                    Line.push({
                        DetailType: "SalesItemLineDetail",
                        SalesItemLineDetail: {
                            ItemRef: {
                                value: "972", // Delivery Fee - Bulk
                            },
                            Qty: 1,
                            TaxCodeRef: {
                                value: "39", // HST ON
                            },
                        },
                        Description: "Delivery Fee - Bulk",
                        Amount: shippingTotal,
                    });
                }

                // CustomerMemo
                let customerMemo = "";
                let deliverySpot = this.getDeliverySpot(this.createInvoiceOrder.meta_data, "");
                let deliveryInstruction = this.getDeliveryInstruction(this.createInvoiceOrder.meta_data, "");
                if (deliveryInstruction != "") {
                    customerMemo += `Delivery Instruction - ${deliveryInstruction} \n`;
                }
                if (deliverySpot != "") {
                    customerMemo += `Driveway Drop Spot - ${deliverySpot} \n\n`;
                }
                customerMemo += "Please note: All bulk orders delivered beyond the curb are done at the homeowner's risk and responsibility";

                let billing = _.get(this.createInvoiceOrder, "billing", {});
                let shipping = _.get(this.createInvoiceOrder, "shipping", {});

                // Final param
                let params = {
                    CustomerRef: {
                        value: customerQBId,
                    },
                    BillEmail: {
                        Address: customerEmail,
                    },
                    CustomField: [
                        {
                            DefinitionId: "1",
                            Name: "P.O. Number",
                            Type: "StringType",
                            StringValue: this.createInvoiceOrder.id,
                        },
                    ],
                    CustomerMemo: {
                        value: customerMemo,
                    },
                    BillAddr: {
                        Line1: _.get(this.createInvoiceOrder, "customerName", ""),
                        Line2: _.get(billing, "address_1", ""),
                        Line3: `${_.get(billing, "city", "")} ON  ${_.get(billing, "postcode", "")}`,
                        Line4: `${_.get(billing, "phone", "")}`,
                    },
                    ShipAddr: {
                        Line1: _.get(this.createInvoiceOrder, "customerName", ""),
                        Line2: _.get(shipping, "address_1", ""),
                        Line3: `${_.get(shipping, "city", "")} ON  ${_.get(shipping, "postcode", "")}`,
                        Line4: `${_.get(billing, "phone", "")}`,
                    },
                    Line,
                    TxnDate: invoiceDate,
                };

                let response = await QuickbooksAPICalls.createInvoiceWoocommerce(params);
                await this.changeHoldToCompleted(this.createInvoiceOrder.id, response.Invoice.DocNumber);
                this.$refs["dc-modal-create-invoice"].hide();
                this.fullScreenSpinner = false;
                this.$toasted.show(`Invoice created ! Invoice number ${response.Invoice.DocNumber}`, {
                    position: "bottom-center",
                    className: "dc-toast-success",
                    duration: 8000,
                });
                this.resetData();
            } catch (e) {
                console.log(e);
                this.fullScreenSpinner = false;
                this.$toasted.show("Error: REFRESH TOKEN in Settings", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
            }
        },
        async createCustomer() {
            this.fullScreenSpinnerText = "Creating Customer...";
            this.fullScreenSpinner = true;
            let customerName = _.get(this.createInvoiceOrder, "customerName", "");
            let billing = _.get(this.createInvoiceOrder, "billing", {});

            let params = {
                GivenName: billing.first_name,
                FamilyName: billing.last_name,
                DisplayName: customerName,
                CompanyName: billing.company,
                FullyQualifiedName: customerName,
                PrintOnCheckName: customerName,
                PrimaryEmailAddr: {
                    Address: billing.email,
                },
                PrimaryPhone: {
                    FreeFormNumber: billing.phone,
                },
                BillAddr: {
                    City: billing.city,
                    Line1: billing.address_1,
                    PostalCode: billing.postcode,
                    CountrySubDivisionCode: "ON",
                    Country: billing.phone,
                },
                ShipAddr: {
                    City: billing.city,
                    Line1: billing.address_1,
                    PostalCode: billing.postcode,
                    CountrySubDivisionCode: "ON",
                    Country: billing.phone,
                },
                CustomerTypeRef: {
                    value: "1500000000000113173", // Homeowner
                },
            };

            try {
                let response = await QuickbooksAPICalls.createCustomer(params);
                console.log(response);
                this.$refs["dc-modal-create-invoice"].hide();
                this.fullScreenSpinner = false;
                this.$toasted.show(`Customer created!`, { position: "bottom-center", className: "dc-toast-success", duration: 8000 });
                this.resetData();
            } catch (e) {
                console.log(e);
                this.$toasted.show("Error: REFRESH TOKEN in Settings", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
                this.fullScreenSpinner = false;
            }
        },
        async changeDeliveryDate(id, deliveryDate) {
            try {
                this.fullScreenSpinnerText = "Changing Date...";
                this.fullScreenSpinner = true;
                await APICalls.changeDeliveryDate(id, deliveryDate);
                this.fullScreenSpinner = false;
                this.$toasted.show(`Change Delivery Date Success!`, { position: "bottom-center", className: "dc-toast-success", duration: 8000 });
            } catch (e) {
                console.log(e);
                this.$toasted.show("Error: Change Delivery Date", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
                this.fullScreenSpinner = false;
            }
        },
        async resetData() {
            this.ordersOnHold = [];
            this.ordersCompleted = [];
            this.createInvoiceFindCustomer = false;
            this.createInvoiceCustomerList = [];
            this.createInvoiceOrder = {};
            this.createInvoiceCustomer = {};
            this.fullScreenSpinner = false;
            this.fullScreenSpinnerText = "";
            await this.getOrdersOnHold();
            await this.getOrdersCompleted();
        },
        async checkCustomerExist(row) {
            try {
                let params = {
                    name: _.get(row, "customerName", ""),
                    address_1: _.get(row, "billing.address_1", ""),
                    address_2: _.get(row, "billing.address_2", ""),
                    email: _.get(row, "billing.email", ""),
                };

                // let params = { name: "yvonne parker" };
                let response = await APICalls.checkCustomerExist(params);
                this.createInvoiceCustomerList = response;
                this.createInvoiceFindCustomer = false;
            } catch (e) {
                console.log(e);
                this.$toasted.show("Error: REFRESH TOKEN in Settings", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
            }
        },
        async getCustomers() {
            try {
                await CustomerAPICalls.getCustomers();
            } catch (e) {
                console.log(e);
                throw 500;
            }
        },
        async changeHoldToCompleted(id, qbId) {
            try {
                let response = await APICalls.changeHoldToCompleted(id, qbId);
                console.log(response);
            } catch (e) {
                console.log(e);
            }
        },
        async getOrdersOnHold() {
            try {
                let orders = await APICalls.getOrdersOnHold();
                this.ordersOnHold = orders;
            } catch (e) {
                console.log(e);
                // this.$router.push({ path: "/login" });
            }
        },
        async getOrdersCompleted() {
            try {
                let orders = await APICalls.getOrdersCompleted();
                this.ordersCompleted = orders;
            } catch (e) {
                console.log(e);
                // this.$router.push({ path: "/login" });
            }
        },
    },
    watch: {
        ordersCompleted: function(orders) {
            _.each(orders, order => {
                // Delivery Type
                let deliveryType = "";
                _.each(order.line_items, line_item => {
                    let name = line_item.name;
                    if (name.includes("Bulk")) {
                        deliveryType += "Bulk\n";
                    } else if (name.includes("Tote")) {
                        deliveryType += "Tote\n";
                    } else if (name.includes("Mini-bags") || name.includes("BAG")) {
                        deliveryType += "Mini-bags\n";
                    }
                });

                // Delivery Date
                let deliveryDate = this.getDeliveryDate(order.meta_data);
                if (deliveryDate != "") {
                    _.set(order, "deliveryDate", this.getDeliveryDate(order.meta_data), "");
                } else {
                    _.set(order, "deliveryDate", "", "");
                }

                _.set(order, "deliveryType", deliveryType);
                _.set(order, "dateCreated", moment(order.date_created).format("dddd, MMM D, YYYY"), "");
                _.set(order, "requestedDeliveryDate", this.getRequestedDeliveryDate(order.meta_data), "");
                _.set(order, "quickbooksId", this.getQuickbooksId(order.meta_data), "");
                _.set(order, "customerName", order.billing.first_name + " " + order.billing.last_name);
            });
        },
        ordersOnHold: function(orders) {
            _.each(orders, order => {
                // Delivery Type
                let deliveryType = "";
                _.each(order.line_items, line_item => {
                    let name = line_item.name;
                    if (name.includes("Bulk")) {
                        deliveryType += "Bulk\n";
                    } else if (name.includes("Tote")) {
                        deliveryType += "Tote\n";
                    } else if (name.includes("Mini-bags") || name.includes("BAG")) {
                        deliveryType += "Mini-bags\n";
                    }
                });

                // Delivery Date
                let deliveryDate = this.getDeliveryDate(order.meta_data);
                if (deliveryDate != "") {
                    _.set(order, "deliveryDate", new Date(this.getDeliveryDate(order.meta_data)), "");
                } else {
                    _.set(order, "deliveryDate", new Date(this.getRequestedDeliveryDate(order.meta_data)), "");
                }

                _.set(order, "deliveryType", deliveryType);
                _.set(order, "dateCreated", moment(order.date_created).format("dddd, MMM D, YYYY"), "");
                _.set(order, "requestedDeliveryDate", this.getRequestedDeliveryDate(order.meta_data), "");
                _.set(order, "customerName", order.billing.first_name + " " + order.billing.last_name);
            });
        },
    },
    mounted() {
        this.getCustomers()
            .then(() => {
                this.getOrdersOnHold();
                this.getOrdersCompleted();
            })
            .catch(e => {
                console.log(e);
                this.$router.push({ path: "/login" });
            });
    },
};
</script>

<style lang="scss">
#dc-woocommerce {
    #dc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    #dc-woocommerce-header {
        display: flex;
        justify-content: space-between;

        .dc-button-primary {
            width: 200px;
            font-size: 16px;
        }
    }

    .dc-actions-container {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .dc-icon-customer {
            width: 24px;
            height: 24px;
            color: black;
            cursor: pointer;
            outline: none;
        }

        .dc-icon-view {
            width: 24px;
            height: 24px;
            color: black;
            cursor: pointer;
            outline: none;
            margin-left: 10px;
        }
    }

    .dc-button-primary {
        margin-top: 10px;
        width: 100px;
        font-size: 12px;
    }

    .VueTables {
        .row {
            // Columns - Dropdown
            .dropdown {
                .dropdown-toggle {
                    background-color: #9eab05 !important;
                    height: 45px;
                }

                .dropdown-menu {
                    padding: 10px;
                    font-size: 18px;
                    top: 80%;
                    margin-left: -6rem;

                    input {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .dc-full-loading-spinner {
        width: 100%;
        height: 100%;
    }

    table {
        thead {
            th {
                text-align: center;
                font-size: 14px;
            }
        }

        tbody {
            tr {
                .table-column-id {
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                }
                .table-column-customer {
                    max-width: 150px;
                    font-size: 14px;
                }
                .table-column-billing {
                    min-width: 200px;
                    font-size: 14px;
                    white-space: pre-wrap;
                }
                .table-column-postcode {
                    font-size: 14px;
                    min-width: 100px;
                    text-align: center;
                }
                .table-column-qty {
                    font-size: 14px;
                    max-width: 40px;
                    text-align: center;
                    white-space: pre-wrap;
                }
                .table-column-product {
                    font-size: 14px;
                    white-space: pre-wrap;
                }
                .table-column-payment {
                    text-align: center;
                    font-size: 14px;
                }
                .table-column-delivery-type {
                    min-width: 90px;
                    text-align: center;
                    font-size: 14px;
                    white-space: pre-wrap;
                }
                .table-column-phone {
                    min-width: 130px;
                    font-size: 14px;
                }
                .table-column-date-created {
                    max-width: 120px;
                    font-size: 14px;
                }
                .table-column-requested-delivery {
                    max-width: 120px;
                    font-size: 14px;
                }
                .table-column-delivery-date {
                    font-size: 14px;
                }
            }

            td {
                padding: 0.5rem !important;
            }

            .vdp-datepicker {
                input {
                    max-width: 125px;
                    font-size: 14px;
                }
            }
        }
    }

    // Order Sidebar
    #app-sidebar-right {
        width: 30vw;
    }
}

#dc-modal-create-invoice {
    #dc-create-invoice-container {
        height: 500px;
        padding: 20px;

        #dc-find-customer {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
        }

        #dc-customer-list {
            font-size: 16px;

            h4 {
                text-align: center;
                margin-bottom: 40px;
            }

            .dc-customer-row {
                display: flex;

                .dc-customer-id {
                    flex: 8;
                    font-weight: bold;
                }

                .dc-customer-name {
                    flex: 20;
                }

                .dc-customer-address {
                    flex: 30;
                }

                .dc-customer-phone {
                    flex: 15;
                }

                .dc-customer-email {
                    flex: 20;
                }

                .dc-customer-radio {
                    flex: 5;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            #dc-customer-not-found {
                height: 350px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }
        }

        #dc-create-invoice-button {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .dc-button-primary {
                margin-left: 40px;
            }
        }
    }
}
</style>
