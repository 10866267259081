<template>
    <div id="dc-sidebar" v-if="orderSidebar && orderSidebar.billing">
        <div id="dc-top">
            <h3># {{ orderSidebar.id }}</h3>
            <b-button class="dc-button-primary" @click="onClickGotoWordpress(orderSidebar.id)">Go To</b-button>
        </div>

        <hr />

        <div>
            <span>Name:</span> <span>{{ orderSidebar.customerName }}</span>
        </div>
        <hr />
        <div>
            <span>Date Created:</span> <span>{{ orderSidebar.dateCreated }}</span>
        </div>
        <div>
            <span>Requested Delivery:</span> <span> {{ getRequestedDeliveryDate(orderSidebar.meta_data) }}</span>
        </div>
        <hr />

        <div>
            <span>Email:</span> <span>{{ orderSidebar.billing.email }}</span>
        </div>
        <div>
            <span>Phone:</span> <span>{{ orderSidebar.billing.phone }}</span>
        </div>
        <div>
            <span>Payment:</span> <span> {{ orderSidebar.payment_method }}</span>
        </div>
        <div>
            <span>Driveway Drop Spot:</span> <span>{{ getDeliverySpot(orderSidebar.meta_data) }}</span>
        </div>
        <div>
            <span>Delivery Instructions:</span> <span>{{ getDeliveryInstruction(orderSidebar.meta_data) }}</span>
        </div>
        <hr />

        <!-- Address -->
        <div class="dc-address">
            <div>
                <h5>Billing</h5>
                <span>{{ orderSidebar.customerName }}</span>
                <span>{{ orderSidebar.billing.address_1 }}</span>
                <span v-if="orderSidebar.billing.address_2">{{ orderSidebar.billing.address_2 }}</span>
                <span>{{ orderSidebar.billing.city }} {{ orderSidebar.billing.state }} {{ orderSidebar.billing.postcode }}</span>
                <span>{{ orderSidebar.billing.phone }}</span>
            </div>
            <div>
                <h5>Shipping</h5>
                <span>{{ orderSidebar.customerName }}</span>
                <span>{{ orderSidebar.shipping.address_1 }}</span>
                <span v-if="orderSidebar.shipping.address_2">{{ orderSidebar.shipping.address_2 }}</span>
                <span>{{ orderSidebar.shipping.city }} {{ orderSidebar.shipping.state }} {{ orderSidebar.shipping.postcode }}</span>
                <span>{{ orderSidebar.billing.phone }}</span>
            </div>
        </div>
        <hr />

        <div>
            <span>Status:</span> <span>{{ orderSidebar.status }}</span>
        </div>
        <div>
            <span>QB #:</span> <span>{{ getQuickbooksId(orderSidebar.meta_data) }}</span>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    props: ["orderSidebar"],
    data() {
        return {};
    },
    methods: {
        onClickGotoWordpress(id) {
            let url = `https://dirtcheap.ca/wp-admin/post.php?post=${id}&action=edit`;
            window.open(url, "_blank").focus();
        },
        getRequestedDeliveryDate(metadata) {
            let requestedDeliveryDate = _.find(metadata, (metadata) => {
                if (metadata.key === "Requested Delivery Date") return metadata;
            });
            return requestedDeliveryDate && requestedDeliveryDate.value ? requestedDeliveryDate.value : "";
        },
        getDeliverySpot(metadata) {
            let deliverySpot = _.find(metadata, (metadata) => {
                if (metadata.key === "_billing_drop_spot") return metadata;
            });
            return deliverySpot && deliverySpot.value ? deliverySpot.value : "";
        },
        getDeliveryInstruction(metadata) {
            let deliveryInstruction = _.find(metadata, (metadata) => {
                if (metadata.key === "_billing_delivery_instructions") return metadata;
            });
            return deliveryInstruction && deliveryInstruction.value ? deliveryInstruction.value : "";
        },
        getQuickbooksId(metadata) {
            let quickbooksId = _.find(metadata, (metadata) => {
                if (metadata.key === "quickbooksId") return metadata;
            });
            return quickbooksId && quickbooksId.value ? quickbooksId.value : "";
        },
    },
    // watch: {
    //     orderSidebar: function(order) {
    //         console.log("Received", order);
    //     },
    // },
};
</script>

<style lang="scss">
#dc-sidebar {
    padding: 20px;

    > div {
        margin-bottom: 10px;
        > span:nth-child(1) {
            font-weight: bold;
            font-size: 18px;
        }

        > span:nth-child(2) {
            margin-left: 10px;
            font-size: 18px;
        }
    }

    #dc-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dc-button-primary {
            margin-top: 0;
            font-size: 16px;
        }
    }

    .dc-address {
        display: flex;

        > div {
            width: 50%;

            span {
                display: block;
            }
        }
    }
}
</style>
