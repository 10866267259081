import axios from "axios";

import * as Endpoints from "./../../constant/api-endpoints";

let checkCustomerExist = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(Endpoints.quickbooks_check_customer_exist_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getOrdersOnHold = function() {
    return new Promise((resolve, reject) => {
        axios
            .get(Endpoints.woocommerce_endpoint + "/on-hold")
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getOrdersCompleted = function() {
    return new Promise((resolve, reject) => {
        axios
            .get(Endpoints.woocommerce_endpoint + "/completed")
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let changeHoldToCompleted = function(id, qbId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${Endpoints.woocommerce_endpoint}/holdToCompleted?op=holdToCompleted&id=${id}&qbId=${qbId}`)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let changeDeliveryDate = function(id, deliveryDate) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${Endpoints.woocommerce_endpoint}/changeDeliveryDate?op=changeDeliveryDate&id=${id}&deliveryDate=${deliveryDate}`)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { checkCustomerExist, getOrdersOnHold, getOrdersCompleted, changeHoldToCompleted, changeDeliveryDate };
